import { useState } from "react";
import apiUrl from "api_url";
import axios, { type AxiosRequestConfig } from "axios";
// import LoadingManager from "constant/LoadingManager";
import { type loadObjectType } from "constant/Types";
import { type AlertColor } from "@mui/material/Alert";
import { ERROR } from "constant/message";
import { ENDPOINT_GET_DIAGNOSTIC_MALADIE } from "./../../constant/moduleInfo";
import { USER_DATA_STORAGE } from "constant";

export default function servicesAPI(): any {
  // const { handleLoad } = LoadingManager as { handleLoad: (value: loadObject) => void };
  // const loadingManager = LoadingManager();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAlert, setIsAlert] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [alertSeverity, setAlertSeverity] = useState<AlertColor>("info");

  const [uploadProgress /* , setUploadProgress */] = useState(0);
  const fileURL = `${apiUrl}/file`;
  function handleError(error: any): void {
    let errorMessage = error?.response?.data?.errorDetails ?? error?.message;
    if (error?.response?.status === 403) {
      errorMessage = "Une double connexion a été détectée. Veuillez vous reconnecter";
    } else if (error?.message === "Network Error") {
      errorMessage = "Vérifier votre connexion internet";
    } else if (error?.code === "ECONNABORTED") {
      errorMessage = "Votre connexion internet est instable ";
    }
    console.log("ddd", errorMessage);
    console.log("error", error);
    const loadData: loadObjectType = {
      isAlert: true,
      message: errorMessage,
      isLoading: false,
      alertSeverity: "error",
    };
    handleLoad(loadData);
  }
  function handleLoad(value: loadObjectType): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setIsAlert(value?.isAlert);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setMessage(value?.message);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setAlertSeverity(value?.alertSeverity);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    setIsLoading(value?.isLoading);
  }

  // Récupération  du fichier pour la visualisation
  const downloadFile = (fileName: string, type: string): string | object => {
    let loadData: loadObjectType;
    let resultData: any;
    // console.log("downloadFile", fileName, type);
    try {
      // const userToken: string | null = localStorage.getItem("user-tokenBCMPSA");
      // if (userToken !== null) {
      // const accessToken = JSON.parse(userToken);
      loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
      handleLoad(loadData);
      const config: AxiosRequestConfig = {
        timeout: 5000,
        /* headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Content-Type": "application/pdf",
          }, */
        responseType: "arraybuffer",
      };
      resultData = axios
        .get(`${fileURL}/download/byFileName?fileName=${fileName}.pdf&businessType=${type}`, config)
        .then((response) => {
          const result = response.data;

          loadData = {
            isAlert: false,
            message: "Fichier Disponible",
            isLoading: false,
            alertSeverity: "success",
          };
          handleLoad(loadData);
          return result;
        })
        .catch((error) => {
          /* console.log("edd", error);
            let message = `${error}`;
            if (error?.response?.status === 400) {
              message = "Fichier Introuvable";
            } else if (error?.response?.status === 403) {
              message = "Une double connexion a été détectée. Veuillez vous reconnecter";
            }
            loadData = {
              isAlert: true,
              // message: `${error}`,
              message,
              isLoading: false,
              alertSeverity: "error",
            };
            console.log(" downloadFile axios error", error);
            handleLoad(loadData); */
          handleError(error);
          return ERROR;
        });
      //  }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };
  // Upload du fichier
  const uploadFile = (file: Blob, name: string): string => {
    let loadData: loadObjectType;
    let resultData: any;
    console.log("uploadFile", name);
    try {
      const userToken: string | null = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        const config = {
          headers: {
            Authorization: `Bearer ${accessToken.access_token}`,
            "Content-Type": "application/pdf",
          },
        };
        const formData = new FormData();
        formData.append("file", file, `${name}.pdf`);
        resultData = axios
          .post(`${fileURL}/saveFile?file`, formData, config)
          .then((response) => {
            const result = response.data;
            console.log("ddd", result);
            loadData = {
              isAlert: true,
              message: "Opération effectuée avec succès",
              isLoading: false,
              alertSeverity: "success",
            };
            handleLoad(loadData);
            return result;
          })
          .catch((error) => {
            /* let message = `${error}`;
            if (error?.response?.status === 403) {
              message = "Une double connexion a été détectée. Veuillez vous reconnecter";
            }
            loadData = {
              isAlert: true,
              message,
              isLoading: false,
              alertSeverity: "error",
            };
            handleLoad(loadData);
            return "axiosError"; */
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };
  // Récupération d'une liste de donnée en fonction de l'endpoint (soit maladie, soit médicament, soit vaccin)
  const getAll = (endPointURL: string): string | object => {
    let loadData: loadObjectType;
    let resultData: any;
    try {
      // console.log("eee", endPointURL);
      // const userToken: string | null = localStorage.getItem("user-tokenBCMPSA");
      // if (userToken !== null) {
      // const accessToken = JSON.parse(userToken);
      const config: AxiosRequestConfig = {
        timeout: 5000,
        // headers: { Authorization: `Bearer ${accessToken.access_token}` },
      };
      // console.log("token ///", accessToken.access_token);
      loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
      handleLoad(loadData);
      resultData = axios
        .get(`${apiUrl}/${endPointURL}`, config)
        .then((response) => {
          const result = response.data;
          let returnData: any;
          // const regex = new RegExp(ENDPOINT_GET_DIAGNOSTIC_MALADIE);
          // const regex = new RegExp(endPointURL);
          // console.log("ddd6", result, endPointURL.includes(ENDPOINT_GET_DIAGNOSTIC_MALADIE));
          if (endPointURL.includes(ENDPOINT_GET_DIAGNOSTIC_MALADIE)) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            if (Object.keys(result).length > 0) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
              // console.log("ddd77", result, Object.keys(result).length > 0);
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }
          } else {
            // Si le résultat est un tableau de donnée
            if (Array.isArray(result) && result.length > 0) {
              loadData = {
                isAlert: false,
                message: "",
                isLoading: false,
                alertSeverity: "success",
              };
              handleLoad(loadData);
              returnData = result;
            } else {
              loadData = {
                isAlert: true,
                message: "Aucune Données Trouvées",
                isLoading: false,
                alertSeverity: "error",
              };
              handleLoad(loadData);
              returnData = "noData";
            }
          }
          // }
          return returnData;
        })
        .catch((error) => {
          /* console.log("ddd//// ", error);
            let message = `${error}`;
            if (error?.response?.status === 403) {
              message = "Une double connexion a été détectée. Veuillez vous reconnecter";
            }
            loadData = {
              isAlert: true,
              message,
              isLoading: false,
              alertSeverity: "error",
            };
            handleLoad(loadData);
            return "axiosError"; */
          handleError(error);
          return ERROR;
        });
      // }
    } catch (error) {
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return "error";
    }
    return resultData;
  };
  const postData = (endPointURL: string, dataPost: any): string | object => {
    let resultData: any;
    let loadData: loadObjectType = {
      isAlert: false,
      message: "",
      isLoading: false,
      alertSeverity: "error",
    };
    // console.log("in post", endPointURL, dataPost);
    try {
      const userToken = localStorage.getItem(USER_DATA_STORAGE);
      if (userToken !== null) {
        const accessToken = JSON.parse(userToken);
        const config = {
          timeout: 5000,
          headers: { Authorization: `Bearer ${accessToken.access_token}` },
        };
        console.log("dd", accessToken.access_token);
        loadData = { isAlert: false, message: "", isLoading: true, alertSeverity: "info" };
        handleLoad(loadData);
        resultData = axios
          .post(`${apiUrl}/${endPointURL}`, dataPost, config)
          .then((response) => {
            const result = response.data;
            return result;
          })
          .catch((error) => {
            /* let errorMessage = error.message;
            //  console.log("fff error", error.response.data.msg);
            if (
              error.response.data !== undefined &&
              error.response.data.errorDetails !== undefined
            ) {
              errorMessage = error.response.data.errorDetails;
            }
            if (error?.response?.status === 403) {
              errorMessage = "Une double connexion a été détectée. Veuillez vous reconnecter";
            }
            loadData = {
              isAlert: true,
              message: errorMessage,
              isLoading: false,
              alertSeverity: "error",
            };
            handleLoad(loadData);
            console.log("in post", error);
            return ERROR; */
            handleError(error);
            return ERROR;
          });
      }
    } catch (error) {
      // console.log("ddd//// ", error);
      loadData = { isAlert: true, message: `${error}`, isLoading: false, alertSeverity: "error" };
      // loadingManager.handleLoad(loadData);
      handleLoad(loadData);
      return ERROR;
    }
    return resultData;
  };
  return {
    downloadFile,
    uploadFile,
    uploadProgress,
    getAll,
    isLoading,
    isAlert,
    message,
    alertSeverity,
    handleLoad,
    postData,
  };
}
