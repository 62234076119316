import React, { useState } from "react";
import { Button, Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import PropTypes from "prop-types";
import MDBox from "components/MDBox";
import { isAdmin } from "utils/userInfos";

function RubriqueDialogAction({
  selectedItem,
  handleOpenFile,
  handleUploadFile,
  handleClose,
  open,
  showName,
}): JSX.Element {
  const [file, setFile] = useState<Blob | null>(null);
  const [file2, setFile2] = useState<Blob | null>(null);
  const [file3, setFile3] = useState<Blob | null>(null);
  const isAdminRole: boolean = isAdmin();
  // const isSuperisAdminRole: boolean = isSuperAdmin();

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{selectedItem.title}</DialogTitle>
        <DialogContent>
          <p>{selectedItem.description}</p>

          {/* <Stack spacing={2} direction="row" sx={{ backgroundColor: "#ffe1c3", padding: "20px" }}> */}
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }} // Change direction to column on small screens
            sx={{
              backgroundColor: "#ffe1c3",
              padding: { xs: "10px", sm: "20px" }, // Adjust padding for different screen sizes
              alignItems: "center", // Center items for better layout on small screens
              width: "100%",
            }}
          >
            <MDBox mb={2}>
              {isAdminRole && (
                <input
                  type="file"
                  name="upload"
                  id="upload"
                  // onChange={onChangeFile}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files !== null) {
                      setFile(e.target.files[0] as Blob);
                    }
                  }}
                  accept="application/pdf"
                  style={{ width: "100%", maxWidth: "100%" }}
                />
              )}
            </MDBox>
            {file === null ? (
              <Button
                onClick={() => handleOpenFile(selectedItem.title, false)}
                color="primary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  //  width: { xs: "100%", sm: "auto" },
                }}
              >
                Voir la fiche {showName}
              </Button>
            ) : (
              <Button
                onClick={() => handleUploadFile(file, selectedItem.title)}
                color="primary"
                autoFocus
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                Uploader une fiche {showName}
              </Button>
            )}
          </Stack>
          {selectedItem.title === "Antiparasitaires" && (
            <div>
              <p>Annexe 1</p>
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "row" }} // Change direction to column on small screens
                sx={{
                  backgroundColor: "#ffe1c3",
                  padding: { xs: "10px", sm: "20px" }, // Adjust padding for different screen sizes
                  alignItems: "center",
                  // Center items for better layout on small screens
                }}
              >
                {isAdminRole && (
                  <MDBox mb={2}>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      // onChange={onChangeFile}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files !== null) {
                          setFile2(e.target.files[0] as Blob);
                        }
                      }}
                      accept="application/pdf"
                      style={{ width: "100%", maxWidth: "100%" }}
                    />
                  </MDBox>
                )}
                {file2 === null ? (
                  <Button
                    onClick={() => handleOpenFile("Traitement épicutané du bétail", false)}
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Voir l&apos;annexe Traitement épicutané du bétail
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleUploadFile(file2, "Traitement épicutané du bétail")}
                    color="primary"
                    autoFocus
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Uploader l&apos;annexe Traitement épicutané du bétail
                  </Button>
                )}
              </Stack>
              <p>Annexe 2</p>
              <Stack
                spacing={2}
                direction={{ xs: "column", sm: "row" }} // Change direction to column on small screens
                sx={{
                  backgroundColor: "#ffe1c3",
                  padding: { xs: "10px", sm: "20px" }, // Adjust padding for different screen sizes
                  alignItems: "center", // Center items for better layout on small screens
                }}
              >
                {isAdminRole && (
                  <MDBox mb={2}>
                    <input
                      type="file"
                      name="upload"
                      id="upload"
                      // onChange={onChangeFile}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (e.target.files !== null) {
                          setFile3(e.target.files[0] as Blob);
                        }
                      }}
                      accept="application/pdf"
                      style={{ width: "100%", maxWidth: "100%" }}
                    />
                  </MDBox>
                )}
                {file3 === null ? (
                  <Button
                    onClick={() => handleOpenFile("pédiluve acaricide", false)}
                    color="primary"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Voir l&apos;annexe Pédiluve Acaricide
                  </Button>
                ) : (
                  <Button
                    onClick={() => handleUploadFile(file3, "pédiluve acaricide")}
                    color="primary"
                    autoFocus
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    Uploader l&apos;annexe Pédiluve Acaricide
                  </Button>
                )}
              </Stack>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
RubriqueDialogAction.defaultProps = {
  selectedItem: {
    id: 0,
    title: "",
    description: "",
  },
  handleOpenFile: () => {},
  handleUploadFile: () => {},
  handleClose: () => {},
  open: false,
  showName: "",
};
RubriqueDialogAction.propTypes = {
  selectedItem: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  handleOpenFile: PropTypes.func,
  handleUploadFile: PropTypes.func,
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  showName: PropTypes.string,
};
export default RubriqueDialogAction;
