export const MODULE_MPSA = "Santé Animal";
export const PATH_MPSA = "/sante_animal";
// export const TITLE_MPSA = "M.U.P.S.A";
export const TITLE_MPSA = "Mallette Pédagogique Santé Animale Océan Indien";
// export const ENDPOINT_GET_USER = "Users";

export const MODULE_MALADIE = "Maladie";
export const PATH_MALADIE = "/sante_animal/maladies";
export const TITLE_MALADIE = "Maladies Animales Prioritaires";

export const MODULE_MALADIE_ZOONOTIQUE = "Maladies Zoonotiques";
export const PATH_MALADIE_ZOONOTIQUE = "/sante_animal/maladies-zoonotique";
export const TITLE_MALADIE_ZOONOTIQUE = "Maladies zoonotique soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_ZOONOTIQUE = "Maladie/byType?maladieType=Zonotique";

export const MODULE_MALADIE_NON_ZOONOTIQUE = "Maladies Non Zoonotiques";
export const PATH_MALADIE_NON_ZOONOTIQUE = "/sante_animal/maladies-non-zoonotique";
export const TITLE_MALADIE_NON_ZOONOTIQUE =
  "Maladies non zoonotiques soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_NON_ZOONOTIQUE = "Maladie/byType?maladieType=NonZonotique";

export const MODULE_MALADIE_NON_OBLIGATOIRE = "Maladies non soumises à déclaration obligatoire";
export const PATH_MALADIE_NON_OBLIGATOIRE = "/sante_animal/maladies-non-obligatoire";
export const TITLE_MALADIE_NON_OBLIGATOIRE = "Maladies non  soumises  à déclaration obligatoire";
export const ENDPOINT_GET_MALADIE_NON_OBLIGATOIRE = "Maladie/byType?maladieType=NonZonotique";

export const MODULE_TECHNIQUE = "Technique de base";
export const PATH_TECHNIQUE = "/sante_animal/techniques";
export const TITLE_TECHNIQUE = "Techniques de base en santé animale";
export const ENDPOINT_GET_TECHNIQUE = "technics-base";

export const MODULE_EXAMEN = "Examen Clinique";
export const PATH_EXAMEN = "/sante_animal/examen-clinique";
export const TITLE_EXAMEN = "Examen Clinique";
export const ENDPOINT_GET_EXAMEN = "technics-base";

export const MODULE_DIAGNOSTIC = "Diagnostic";
export const PATH_DIAGNOSTIC = "/sante_animal/diagnostic";
export const TITLE_DIAGNOSTIC = "Diagnostics Différentiels";
export const ENDPOINT_GET_DIAGNOSTIC = "diagnostic";
export const ENDPOINT_GET_DIAGNOSTIC_MALADIE = "diagnostic/maladies?diagnosticName=";

export const MODULE_TRAITEMENT = "Traitement";
export const PATH_TRAITEMENT = "/sante_animal/traitements";
export const TITLE_TRAITEMENT = "Principaux Traitements";

export const MODULE_MEDICAMENT = "Medicament";
export const PATH_MEDICAMENT = `/sante_animal/${MODULE_TRAITEMENT}/medicaments`;
export const TITLE_MEDICAMENT = "Medicaments";
export const ENDPOINT_GET_MEDICAMENT = "Medicament/get-all-medicaments";

export const MODULE_VACCIN = "Vaccin";
export const PATH_VACCIN = "/sante_animal/vaccins";
export const TITLE_VACCIN = "Vaccins";
export const ENDPOINT_GET_VACCIN = "Vaccin/get-all-vaccins";

export const MODULE_PRELEVEMENT = "Prelevements";
export const PATH_PRELEVEMENT = "/sante_animal/prelevements";
export const TITLE_PRELEVEMENT = "Prélèvements";
export const ENDPOINT_GET_PRELEVEMENT = "Vaccin/get-all-vaccins";

export const MODULE_TECHNIQUE_VETERINAIRE = "Techniques vétérinaires";
export const PATH_TECHNIQUE_VETERINAIRE = "/sante_animal/techniques_veterinaires";
export const TITLE_TECHNIQUE_VETERINAIRE = "Techniques Vétérinaires";
export const ENDPOINT_TECHNIQUE_VETERINAIRE = "Vaccin/get-all-vaccins";

export const MODULE_AUTEUR = "Auteurs rédacteurs copyrights";
export const PATH_AUTEUR = "/sante_animal/copyrights";
export const TITLE_AUTEUR = "Auteurs rédacteurs copyrights";
export const ENDPOINT_AUTEUR = "Vaccin/get-all-vaccins";

export const MODULE_ANNEXE = "Annexe";
export const PATH_ANNEXE = "/sante_animal/annexes";
export const TITLE_ANNEXE = "Annexes";
export const ENDPOINT_GET_ANNEXE = "annex";
export const ENDPOINT_UPLOAD_ANNEXE = "file/attachBy-BusinessType";

export const DEFAULT_PATH = PATH_MPSA;

export const PATH_LOGOUT = "/logout";
export const PATH_LOGIN = "/login";
