/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Paper, Typography, TextField } from "@mui/material";
import bgImage from "assets/images/VA.jpg";
// import betaclic_logo from "assets/images/log1.png";
import betaclicLogo from "assets/images/identite/fullLogo1.svg";
import clientLogo from "assets/images/identite/client.png";
import api_url from "api_url";
import AlertContent from "components/MDAlert/AlertContent";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MDButton from "components/MDButton";
// import PhoneInput from "react-phone-number-input/input";
// import routes_data from "router/routes";
import MDInput from "components/MDInput";
import CircularProgress from "@mui/material/CircularProgress";
import MDBox from "./../../../components/MDBox/index";
import PswUpdateComponent from "examples/Navbars/DashboardNavbar/component/PswUpdateComponent";
import "../Basic.css";
import COLORS from "constant/colors";
import { USER_DATA_STORAGE } from "constant/index";
import { DEFAULT_PATH } from "../../../constant/moduleInfo";
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  image: {
    backgroundImage: `url(${bgImage})`, // Replace with your image URL
    backgroundRepeat: "no-repeat",
    backgroundColor: COLORS.primary,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  center_div: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "10vh",
    // backgroundColor: "red",
  },
}));

const SignInPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const [identifiant, setIdentifiant] = useState("");
  const [isErrorIdentifiant, setIsErrorIdentifiant] = useState(false);
  const [errorIdentifiant, setErrorIdentifiant] = useState("");
  const [psw, set_psw] = useState("");
  const [isError_psw, set_isError_psw] = useState(false);
  const [error_psw, set_error_psw] = useState("");

  const [isLoading, set_isLoading] = useState(false);

  const [isError, set_isError] = useState(false);
  const [error_msg, set_error_msg] = useState("");
  const [openDialogUser, setOpenDialogUser] = useState(false);

  const [userToken, setUserToken] = useState(null);

  const handleUserDialog = () => {
    setOpenDialogUser(!openDialogUser);
  };

  const USER_API_URL = `${api_url}/auth/authenticate`;

  const handleLoadData = (value) => {
    set_isLoading(value.isLoading);
    set_isError(value.isError);
    set_error_msg(value.error_msg);
  };
  const go_to_main = (user_token) => {
    localStorage.clear();
    /* let user_roles = [];
    if (user_token !== null) {
      user_roles = user_token.role;
    } */
    /* const data_res = [];
    routes_data.forEach((i, idx, arr) => {
      const isAvalaible = i.role
        .map((el1) => ({
          name: el1,
          // match: user_roles.some((el2) => el2.roleName === el1),
          match: user_token.role === el1,
        }))
        .some((x) => x.match);
      if (isAvalaible) {
        data_res.push(i);
      }
      if (idx === arr.length - 1) {
        return data_res;
      }
      return "success";
    }); */
    /* if (Array.isArray(data_res) && data_res.length > 0) {
      console.log("ddd", data_res[0].route);
    } */
    setTimeout(() => {
      // navigate("/");
      localStorage.setItem(USER_DATA_STORAGE, JSON.stringify(user_token));
      if (psw === "123456") {
        // console.log("ddd999", psw);
        handleUserDialog();
      } else {
        // if (Array.isArray(data_res) && data_res.length > 0)
        // navigate(data_res[0].route);
        navigate(DEFAULT_PATH);
      } /* else {
        alert("Aucune interface trouvé");
      } */
    }, 200);
  };
  function parseJwt(token) {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map((c) => {
          const c_toString = c.charCodeAt(0).toString(16);
          const c_toString2 = `00${c_toString}`;
          const c_toString3 = c_toString2.slice(-2);
          const c_toString4 = `%${c_toString3}`;

          // return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          return c_toString4;
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  const getAllData = async (connectedUser) => {
    try {
      const endpoint = `${api_url}/auth/users`;
      return await axios.get(`${endpoint}`).then((res) => {
        let result = null;
        // console.log("getAllData", res);
        if (Array.isArray(res.data)) {
          const user = res.data.find((i) => i.email === connectedUser.email);

          result = user?.role ?? "";
          // console.log("getAllData result", user);
        } else {
          loadData = { isLoading: false, isError: true, error_msg: "Aucun utilisateur trouvé" };
          handleLoadData(loadData);
          result = "erreur";
        }
        // console.log("getAllData result", result);
        return result;
      });
    } catch (error) {
      console.log("getAllData error", error);
      loadData = { isLoading: false, isError: true, error_msg: `${error}` };
      handleLoadData(loadData);
      return "erreur";
    }
  };
  const get_user_info = (data) => {
    let loadData = {};
    try {
      loadData = { isLoading: true, isError: false, error_msg: "" };
      handleLoadData(loadData);
      axios
        .post(`${USER_API_URL}`, data)
        .then(async (response) => {
          const result = response.data;
          // console.log("ffff", result);
          if (result.access_token !== undefined) {
            const read_jwt = await parseJwt(result.access_token);
            /// result.roles = [{ roleName: "ADMIN" }];
            console.log("result", result);
            const roleUser = await getAllData(result);

            console.log("roleUser", roleUser);
            if (JSON.stringify(read_jwt) !== "{}") {
              // if (result?.roles?.length > 0) {
              if (roleUser !== "error") {
                const data_user_jwt = {
                  ...result,
                  ...read_jwt,
                  ...{ role: roleUser },
                };
                loadData = { isLoading: false, isError: false, error_msg: "" };
                handleLoadData(loadData);
                setUserToken(data_user_jwt);
                go_to_main(data_user_jwt);
              } else {
                console.log("no role");
                loadData = { isLoading: false, isError: true, error_msg: "Aucun role assigné" };
                handleLoadData(loadData);
              }
            } else {
              console.log("no token");
              loadData = { isLoading: false, isError: true, error_msg: "Aucun token trouvé" };
              handleLoadData(loadData);
            }
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log("get_user_info error", error);
            loadData = {
              isLoading: false,
              isError: true,
              // error_msg: `${error.response.data} ${error.response.status}`,
              error_msg: "Vérifiez vos données d'accès ou Contactez votre administrateur",
            };
            // console.log("get_user_info error", load_data.error_msg);
            handleLoadData(loadData);
          }
        });
    } catch (error) {
      console.log("get_user_info catch error", error);
      loadData = { isLoading: false, isError: true, error_msg: `${error}` };
      handleLoadData(loadData);
    }
  };
  const check_form = () => {
    const loadData = { isLoading: false, isError: false, error_msg: "" };
    // console.log("ffff", identifiant);
    handleLoadData(loadData);
    if (identifiant === "") {
      setIsErrorIdentifiant(true);
      setErrorIdentifiant("Veuillez saisir le numéro de identifiant");
    } else if (psw === "") {
      set_isError_psw(true);
      set_error_psw("Veuillez saisir votre mot de passe");
    } else {
      const data = {
        // numTel: telephone.replace("+222", ""),
        email: identifiant,
        password: psw,
      };
      // set_isLoading(true);
      get_user_info(data);
    }
  };
  /* const onChangeTelephone = (value) => {
    set_telephone(value);
    set_error_telephone("");
    set_isError_telephone(false);
  }; */
  return (
    <Grid container component="main" className={classes.root}>
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          {/* <img src={betaclic_logo} className="img-flui" alt="Betaclic" /> */}
          <div className="logoSignin-row">
            <img src={clientLogo} alt="ASTRE" className="logoSigninClient" />
          </div>
          <Typography component="h2" variant="h5">
            Connectez-vous!
          </Typography>
          <form className={classes.form} noValidate>
            {/* <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="telephone"
              label="Numéro de Téléphone"
              name="telephone"
              autoComplete="telephone"
              autoFocus
              error={isError_telephone}
              helperText={error_telephone}
              InputProps={{
                inputComponent: PhoneInput,
                inputProps: {
                  value: telephone,
                  onChange: onChangeTelephone,
                  placeholder: "Saisir le numéro de téléphone",
                  defaultCountry: "MR",
                  maxLength: 11,
                },
              }}
            /> */}
            <MDInput
              type="text"
              label="Identifiant"
              fullWidth
              onChange={(event) => {
                setIdentifiant(event.target.value);
              }}
              error={isErrorIdentifiant}
              helperText={errorIdentifiant}
              // required
              defaultValue={identifiant}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  check_form();
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Mot de passe"
              type="password"
              id="password"
              autoComplete="current-password"
              error={isError_psw}
              helperText={error_psw}
              onChange={(event) => {
                set_psw(event.target.value);
                set_error_psw("");
                set_isError_psw(false);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  check_form();
                }
              }}
            />
            {/* <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="info"
              className={classes.submit}
              onClick={() => check_form()}
            >
              Se Connecter
            </Button> */}
            <div className={classes.center_div}>
              <MDButton
                // type="submit"
                variant="gradient"
                color="info"
                // sx={{ marginLeft: "auto", marginRight: 10 }}
                // style={{}}
                onClick={() => {
                  check_form();
                }}
              >
                Se Connecter
              </MDButton>
            </div>
            <div className="logoSignin-row">
              <img src={betaclicLogo} alt="Betaclic" className="logoSignin" />
            </div>
            {/* isError || (isLoading && <AlertContent color="error" msg={error_msg} />) */}
            {isLoading && (
              <MDBox mt={3} mb={1} textAlign="center" sx={{ alignContent: "center" }}>
                <CircularProgress />
              </MDBox>
            )}
            {isError && <AlertContent color="error" msg={error_msg} />}
          </form>
        </div>
        {openDialogUser && (
          <PswUpdateComponent
            open={openDialogUser}
            handleClose={handleUserDialog}
            userToken={userToken}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SignInPage;
