import React from "react";
import User from "layouts/gestionUser";
import SanteAnimale from "layouts/gestion_sante_animale";
import RubriqueSanteAnimal from "layouts/gestion_sante_animale/rubrique";
// import NotAvailable from "layouts/notAvailable";
import Icon from "@mui/material/Icon";
import { HealthAndSafety } from "@mui/icons-material";
import {
  PATH_DIAGNOSTIC,
  PATH_EXAMEN,
  PATH_MALADIE,
  PATH_MPSA,
  PATH_PRELEVEMENT,
  PATH_TECHNIQUE,
  PATH_TECHNIQUE_VETERINAIRE,
  // PATH_MEDICAMENT,
  PATH_TRAITEMENT,
  TITLE_DIAGNOSTIC,
  TITLE_EXAMEN,
  TITLE_MALADIE,
  TITLE_PRELEVEMENT,
  // TITLE_MPSA,
  TITLE_TECHNIQUE,
  TITLE_TECHNIQUE_VETERINAIRE,
  TITLE_TRAITEMENT,
  PATH_AUTEUR,
} from "constant/moduleInfo";

const routesData = [
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>M.U.P.S.A</span>,
    key: "sante_animal",
    icon: <HealthAndSafety fontSize="small" style={{ color: "black" }} />,
    // route: "/sante_animal",
    route: PATH_MPSA,
    component: <SanteAnimale />,
    // role: ["ADMIN", "USER"],
    role: [],
    collapse: [
      {
        name: "Analytics",
        key: "analytics",
        route: "sante_animal",
        component: <SanteAnimale />,
        collapse: [],
      },
      {
        name: TITLE_TECHNIQUE,
        key: "techniques",
        route: PATH_TECHNIQUE,
        component: <RubriqueSanteAnimal />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      },
      {
        name: TITLE_EXAMEN,
        key: "examen",
        route: PATH_EXAMEN,
        component: <RubriqueSanteAnimal />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      },
      {
        name: TITLE_MALADIE,
        key: "maladies",
        route: PATH_MALADIE,
        component: <SanteAnimale />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      },

      {
        name: TITLE_DIAGNOSTIC,
        key: "diagnostics",
        route: PATH_DIAGNOSTIC,
        component: <RubriqueSanteAnimal />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      },
      {
        name: TITLE_PRELEVEMENT,
        // key: "diagnostics",
        route: PATH_PRELEVEMENT,
        component: <RubriqueSanteAnimal />,
        collapse: [],
      },
      /* {
        name: "Medicaments",
        key: "medicaments",
        route: PATH_MEDICAMENT,
        component: <RubriqueSanteAnimal />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      }, */
      {
        name: TITLE_TRAITEMENT,
        key: "traitements",
        route: PATH_TRAITEMENT,
        component: <SanteAnimale />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      },
      {
        name: TITLE_TECHNIQUE_VETERINAIRE,
        // key: "diagnostics",
        route: PATH_TECHNIQUE_VETERINAIRE,
        component: <RubriqueSanteAnimal />,
        collapse: [],
      },
      {
        name: "Auteurs, Rédacteurs, Copyrights",
        // key: "diagnostics",
        route: PATH_AUTEUR,
        component: <RubriqueSanteAnimal />,
        collapse: [],
      },
      /* {
        name: "Vaccins",
        key: "vaccins",
        route: "/sante_animal/vaccins",
        component: <RubriqueSanteAnimal />,
        // icon: <Coronavirus fontSize="small" style={{ color: "black" }} />,
        collapse: [],
      }, */
    ],
  },
  {
    type: "collapse",
    name: <span style={{ color: "black" }}>Utilisateurs</span>,
    key: "utilisateurs",
    icon: (
      <Icon fontSize="small" style={{ color: "black" }}>
        peopleAltIcon
      </Icon>
    ),
    route: "/utilisateurs",
    component: <User />,
    role: ["ADMIN"],
    collapse: [],
  },
  /* {
    type: "collapse",
    name: <span style={{ color: "black" }}>logout</span>,
    key: "logout",
    icon: (
      <Icon fontSize="small" style={{ color: "black" }}>
        logout
      </Icon>
    ),
    route: "/logout",
    component: <Logout />,
    role: ["ADMIN", "User"],
    collapse: [],
  }, */
];

export default routesData;
